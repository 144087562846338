
.second_home_main_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.second_home_bloc{
    display: flex;
    background-color: rgba(51, 59, 74, 0.35);
    margin: 3rem;
    
    width:85%;
    border-width: 2px;
    
    border: #0B1A30 solid 2px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 25px;
    padding: 10px;
   
}
.second_home_bloc:hover{
    background-color: rgb(15, 13, 20);
    box-shadow: 10px 5px 5px rgb(132, 128, 128);
}
.second_home_bloc::after{
    background-color: #ed5c19;
}
.second_home_icon{
   max-width:40%;
   max-height:25% ;
   margin: 5px;
}
.second_home_bloc :nth-child(1){
    display: flex;
    flex-direction: column;
    padding-left:3rem ;
    width:80% ;
   
    color:white ;
}
.second_home_bloc :nth-child(2){
    display: flex;
    justify-content: center;
    
}
.bloc_title{
    font-size: size 1.5rem; 
    color: #ed5c19;
    width:100%;
}
.bloc_description{
    font-size:1.2rem;
    color: white;
    max-height: 200px;
    font-family:'Courier New', Courier, monospace ;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;

  }
  .second_home_bloc{
      display: flex;
      background-color: rgba(182, 171, 171, 0.35);
      margin: 5px;
      margin-top: 10px;
      max-width: 90%;
      height: auto;
      border-width: 2px;
      border: #081ac4 solid 2px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: 25px;
      flex-wrap: wrap;

      padding: 0px;
  }
  
  .second_home_icon{
    display: flex;

     width:50% ;
     height:auto ;
     border-radius: 25px;

  }
  .bloc_title{
      color:rgb(230, 138, 1) ;
      font-size: size 1.5rem; 
  }
  .bloc_description{
      font-size:1.2rem;
      color: rgb(12, 12, 12);
      max-height: 200px;
  
   text-overflow: ellipsis;
  
   overflow: hidden;
   white-space: normal;
  }