:root{
    --color_1:'#1959DB' ;
    --color_2:'#b71540' ;
    --color_3:'#F55023' ;
    --color_4:'#021438' ;
}
.home_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
     
}
.actuCarou{
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin-top:100px;}
.home_body{
    justify-content: center;
    align-items: center;

}
.block{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color:#0B1A30BF;
   height: 400px;
  width: 400px;
  border-radius: 15px;
}
.block1{
    object-fit: cover;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 400px;
    width: 100%;
    border-radius: 15px;

}
.home_link{
    text-decoration: none;
    padding:3px;
    margin:2px;
    font-size: x-large;
    font-family: 'Courier New', Courier, monospace;
    font-style: normal;
    max-height: 300px;
}
.home_logo{
    width:70%;
    height: 40%;
}
.img_Domotique{
    width:98%;
    height: 99%;
}
.home_link_container :hover{
    background-color:#F55023 ;
    color:white;
}
.header_bloc_two{
    padding-top:20px;
}
.slogan{
    color: #F55023;
    font-size: 2rem;
    font-weight: bold;
    font-style: italic;
    margin-top: 20px;

}
hr{
    background-color: #0B1A30;
    height: 3px;
}
/*  body_containe*/
.body_container_main{
    
    height: 100%;
    justify-content: center;
    align-items: certer;
}
.carasoul_container{
    background-color: white;
}
.title_inter_bloc{
    color:#0B1A30 ;
    text-align: center;
    margin-top: 5rem;
    font-family: 'Courier New', Courier, monospace;
    font-style: italic;
    font-weight: bold;

}
.solution_main_container{
    display: grid;
    grid-template-columns: auto auto;
    
    
}

.solution_container{
   display: flex; 
  
   justify-content: center;
   align-items: center;
   margin: 1rem;
   min-height: 300px;
}
.solution_image{
    object-fit: cover;
    max-width: 200%;
    max-height: 200px;
}
.solution_part_one{
    height: 100%;
    width: 50%;
}
.solution_container:hover{
    box-shadow: 5px 5px 20px 10px #b71540BF;
}
.solution_part_two {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 50%;
}
.solution_part_two:hover{
    background-color:aqua;
    
}

.item_image{
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}
.carasoul_container{
    margin-bottom: 20px;
}
.title_separator1{
    border-color: #F55023;
    border-bottom: 4px;
    
}
.CCol_colorer3{
    background-color: rgb(35, 35, 39);
}
.CCol_colore{
    background-color: rgb(255, 255, 255);
}
.crow_marg{
    margin-top: 20px;
    margin-left: 5px;
}
.les-solution{
    font: 1em sans-serif;
    width: auto;
    height: 70px;
    text-align: center;
    align-content: center;
}
@media only screen and (max-width: 600px) {
    .solution_main_container{
        display: grid;
        grid-template-columns: auto ;      
        
    }
  }

  /*============================ footer    ============*/
  .footer{
    background-color:#021438 ;
    height: max-content;
  }
  .footer_part2{
    font-variant:var(--color_3) ;
  }
