.nc{
    margin-bottom: 1.2rem;
    }
    .nc_title{
        text-align: center;
    }
    .new_img{
        max-width: 200px;
        object-fit: contain;
    }
    .new_main_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        padding-top: 5px;
        
    }
    .new_container{
         display: flex;
        flex-direction:row;
        flex-wrap: wrap;
       
    }
    .new_container_1{
        display: grid;
        grid-template-columns: auto auto; 
        gap: 2rem;
        width: 80%;
        padding: 5px;
      
    }
    
    .new_container_2{    
        width:20%;
    }
    .v_plus{
        width: fit-content;
        padding: 10px;
        border-radius: 25px;
        border-color: rgba(218, 208, 208, 0.9);
        color: rgba(218, 208, 208, 0.9);
        border-width: 2px;
        border-style: solid;
    }
    .v_plus_link{
        text-decoration:none;
         color: rgba(218, 208, 208, 0.9);
        
    }
    .v_plus:hover{
        box-shadow: 5px 5px 10px brown;
        color: brown;
    }
    @media only screen and (max-width: 600px) {
      .new_container_1{
        display:grid;
        grid-template-columns: auto;
        width: 100%;
    }
    .new_container_2{
        width: 0px;
        height: 0px;
        overflow: hidden;
    }
    }
    .img_style{
        box-shadow: 'rgb(0,0,0,0.44) 0px 5px 5px'
    }
      /* Appliquer une transformation de mise à l'échelle à l'image */
.img_new {
    transition: transform 0.3s ease-in-out;
  }
  
  /* Lorsque la souris survole l'image, agrandir l'image */
  .img_new:hover {
    transform: scale(1.02);
  }