.main{
    justify-content:center ;
    align-items:center ;
}
.about_container{
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    padding: 10px;
    padding-top: 20px;
    flex-wrap: wrap;
    
}
.about_bloc_container{
    display:flex ;
    flex-direction: column;
    max-width: 500px;
    border-radius: 20px;
    color:white;
    padding:10px;
    background-color:#16151a6b;
    justify-content: center;
  align-items: center;
  margin-top: 3rem;
  min-height: 300px;
  transition:0.5s;
 
}

.icon{
    width: 160px;
    height:160px;
    border-radius: 180px;
    padding:2px ;
    border:2px #F55023 solid ;
    
}
.about_bloc_container:hover {
    background-color: #5a4d49;
}

.about_bloc_des{
     font-family: 'Courier New', Courier, monospace;
     margin: 5px;
     max-height: 110px;
     overflow: hidden;
     text-overflow: ellipsis;
     word-spacing: normal;
    text-indent: 30px;
}
.img{
    width:120px;
    height: 120px;
}
.line{
    max-width: 300px;
    min-width: 200px;
    margin: 10px;
    margin-left: 20px;
    margin-right: 20px;   
}
.title{
    color:#F55023;
    font-size: x-large;
    font-style: italic;
    font-weight: bold;
}
.main_bloc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    padding: 15px;
    justify-items: center;
    align-items: center;
    background-image :url('../assets/MBE.jpg') url('../assets/MBE.jpg') ;
    background-repeat: no-repeat repeat;
}
.about_bloc{
    margin-top: 5%;
}
.about_first{
    background-color:#16151a6b;
    justify-content: center;
    align-items: center;
    color: white;
    align-content:center ;
    width: 500px;
    margin-bottom: 20px;
}

.about_container_team{
justify-content: center;
align-items: center;
color: white;
}
.team_bloc{
    
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}
.title{
    color: #F55023;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: medium;
}
.team_elemnt{
    margin: 10px;
    padding: 10px;
     background-color: #16151a6b;
    border-radius: 15px;
}
.team_elemnt_image{
    margin: 4px;
    min-width: 100px;
    max-width: 200px;

}
/***/
.main_container{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap:10px ;
    width: 100%;
    padding: 10px;
    justify-content:space-around;
}
@media only screen and (max-width: 1200px) {
    .main_container{
    display: grid;
    grid-template-columns: auto ;
  
}
}
@media only screen and (max-width: 600px) {
   
}