.main_container_activity{
    background-color:blue ;
    height:100%;
}
.grid_container{
 display:grid ;
 background-color: aqua;
 height: 100%;

grid-template-columns: auto auto;
}
.bloc_container{
    background-color: black;
    color:white ;
}
.bloc_container:hover{
  background-color:coral ;
}
.bloc_header{
height:20px ;
background-color:coral;
}
.img_bloc{
  display: block;
  margin-left: auto;
  margin-right: auto;
  
  object-fit: cover;
}
.maincontainer{
 display: grid;
 grid-template-columns:auto auto;
 gap: 4px;
 color: white;

}
.item{
    display: flex;
    background-color: #0B1A30;
    justify-content: center;
    justify-items: center;
    align-items: center;
    opacity: 1;
  transition: opacity ease-in-out 0.25s;
}
.item :hover{
    opacity: 0.2;
}



.nc{
  margin-bottom: 1.2rem;
  }
  .nc_title{
      text-align: center;
  }
  .new_img{
      max-width: 10s0px;
      object-fit: contain;
  }
  .new_main_container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      padding-top: 5px;
      
  }
  .new_container_act{
       display: flex;
      flex-direction:row;
      flex-wrap: wrap;
     
  }
  .new_container_1_act{
      display: grid;
      grid-template-columns: auto auto auto auto;
      gap: 2rem;
      width: 100%;
      padding: 5px;
    
  }
  
  .new_container_2_act{    
      width:20%;
  }
  .v_plus{
      width: fit-content;
      padding: 10px;
      border-radius: 25px;
      border-color: rgba(218, 208, 208, 0.9);
      color: rgba(218, 208, 208, 0.9);
      border-width: 2px;
      border-style: solid;
  }
  .v_plus_link{
      text-decoration:none;
       color: rgba(218, 208, 208, 0.9);
      
  }
  .v_plus:hover{
      box-shadow: 5px 5px 10px brown;
      color: brown;
  }
  @media only screen and (max-width: 600px) {
    .new_container_1_act{
      display:grid;
      grid-template-columns: auto;
      width: 100%;
  }
  .new_container_2_act{
      width: 0px;
      height: 0px;
      overflow: hidden;
  }
  }
  .img_style{
      box-shadow: 'rgb(0,0,0,0.44) 0px 5px 5px'
  }
  .dimetion{
    margin-top: 100px;
  } 
  /* Appliquer une transformation de mise à l'échelle à l'image */
.img_act {
    transition: transform 0.3s ease-in-out;
  }
  
  /* Lorsque la souris survole l'image, agrandir l'image */
  .img_act:hover {
    transform: scale(1.1);
  }
  .dimetion_act{
    margin-top: 10%;
  }