.vision_container{
    height: 10%;
    display: flex;
    background-color: #0B1A30;
    min-height: 200px;
    padding: 10px ;
    flex-wrap: wrap;
    justify-content: space-around;
    color: white;
    margin-bottom: 2rem;

}
.valeur_container_ele{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    overflow: hidden;
    text-overflow:ellipsis ;

}

.vc{
    margin-top: 4rem;
}
.vision_image{
    object-fit: cover;
    width: 180px;
    height: 180px;
    border-radius: 180px;
}
.vision_image:hover{
background-color: brown;
padding: 2px;
}
.vision_title{
    font-size: 1.2rem;
    font-family: 'Courier New', Courier, monospace;
    font-style: italic;
}
.hv{
    font-size: 2rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: bold;
    text-align: center;
}