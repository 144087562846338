@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

body {
  background-color: #ffffff;
  font-family: 'Quicksand', sans-serif;
}

.header {
  color: rgb(28, 18, 158);
  display: flex;
  justify-content: start;
  padding: 5px;
  border-bottom: rgb(57, 24, 85) 2px solid;
}

.logo {
  max-width: 150px;
  height: auto;
}


.header_list {
  list-style: none;
  display: flex;
  padding: 0px;
  margin: 0px;
  margin-left: auto;
  margin-top: 0px;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.header_list li {
  margin-left: 1rem;
  font-size: 1.2rem;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  padding: 2px;
  color: rgb(33, 38, 161);
}

.header_list li:hover {
  background-color: #f55023;
}

.header_list li:hover :active {
  background-color: #f55023;
  color: white;
}

.header_li:hover {
  background-color: #f55023;
  color: white;
  border: #f55023 4px solid;
  padding: 0;
  border-radius: 5px;
}

.header_link_button {
  text-decoration: none;
  color: rgb(33, 38, 161);
}

.header_link_button:hover {
  text-decoration: none;
  background-color: #f55023;
  border: #f55023 4px solid;
}

.slogan{ 
    text-transform:uppercase ;
    background-image:linear-gradient(
        -225deg ,
        #021438 0%,
        #021438 29% ,
        #de11a0 65%,
        #ed5c19 100%
        ) ;
    
    background-size:auto auto ; 
    background-clip:border-box ; 
    background-size:200% auto ;
    color:#fff ; 
    background-clip:text ;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 40px;
    margin-left: 40% ;
   

}
@keyframes textclip {
    to{
        background-position: 200% center;
    }
}

@media only screen and (max-width: 768px) {
    .header_items {
      display: flex;
      align-items: center;
    }
    .header {
      color: white;
      display: grid;
      grid-template-columns: auto auto auto;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      padding: 5px;
      border-bottom: white 2px solid;
    }
    .header_list {
      margin-right: 0%;
    }
    .header_list1{
      margin-right: 0%;
    }

    .header_list li {
      margin-bottom: 2px;
    }
    .header_des {
      margin: 0px;
      margin-left: 0px;
      color: white;
      visibility: hidden;
      font-size: 1.2rem;
      height: 0px;
      padding: 0px;
    }
    .slogan {
      display: none;
    }
    .header_block_on {
      display: flex;
      align-items: center;
    }
    .logo {
      max-width: 100px;
      margin-right: 20%;

    }
    .header-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .header-container .logo {
      margin-bottom: 0;
    }
    .header-container .header_list {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
