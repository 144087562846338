.logog{
    width:30%;
   height: 40%;
  
}
.contact_container{
    display:flex ;
    flex-wrap: wrap;
    justify-items: center;
    margin-top: 25px;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.contact_bloc{
    color:rgb(44, 7, 131);
    margin-top: 25px;
   

}
.mail{
    font-size: 22px;
    font-style: normal;
    font-weight: bold;
    color: #F55023;
}
.contact_container1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    width: 100%;
    padding: 10px;

}
.contact_bloc{
    display: flex;
    justify-content: center;
     align-items: center;
    flex-direction: column;
   
 
}
.icon_contact{
    width: 150px;
    height: 160px;
    border-radius: 160px;
}