.g_container{
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    padding: 4rem;
    margin-top: 10rem;
}
.image_container{
 
  background-color: white;
}
.image_item{
   width: 100%;
}

@media only screen and (max-width: 600px){
    .g_container{
        display: grid;
    grid-template-columns: auto;
        margin-top: 1rem;
    }
}